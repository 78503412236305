import React, { useContext, useState, useEffect } from "react";
import { config } from "../../Constants";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import LoginContext from "../various/LoginContext.jsx";
import BackendPage from "./BackendPage";
import ErrorSnackbar from "../various/ErrorSnackbar";
import SuccessSnackbar from "../various/SuccessSnackbar";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(theme => ({
  formLabel: { fontWeight: "bold", marginLeft: theme.spacing(2) },
  green: { color: "green" },
  red: { color: "red" },
  buttongrid: { marginTop: theme.spacing(3) },
  actionbutton: { marginRight: theme.spacing(1) }
}));

export default function Administrator() {
  const { loggedIn, login, loginToken } = useContext(LoginContext);
  const classes = useStyles();
  let { administratorid } = useParams();
  const [data, setData] = useState({});
  const [requeryTime, setRequeryTime] = useState(0);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [passwordDialogNewPassword, setPasswordDialogNewPassword] = useState(
    ""
  );
  const [showErrorSB, setShowErrorSB] = useState(false);
  const [errorSBMessage, setErrorSBMessage] = useState("");
  const [showSuccessSB, setShowSuccessSB] = useState(false);
  const [successSBMessage, setSuccessSBMessage] = useState("");

  useEffect(() => {
    if (loggedIn) {
      axios
        .get(config.api + "/api/backend/action", {
          params: {
            action: "administrators-detail",
            login: login,
            id: administratorid
          },
          headers: {
            Authorization: "Bearer " + loginToken
          }
        })
        .then(response => {
          if (response.data.success) {
            setData(response.data.data);
          }
        })
        .catch(error => {
          if (error.response.data.message)
            setErrorSBMessage(error.response.data.message);
          else setErrorSBMessage(error);
          setShowErrorSB(true);
        });
    }
  }, [loggedIn, login, loginToken, administratorid, requeryTime]);

  const handleTextfieldChange = name => event => {
    const newValue = event.target.value;
    setData(prevState => {
      return { ...prevState, [name]: newValue };
    });
  };

  const handleCheckboxChange = name => event => {
    const newValue = event.target.checked;
    setData(prevState => {
      return { ...prevState, [name]: newValue };
    });
  };

  const saveDetails = () => {
    if (loggedIn) {
      axios
        .get(config.api + "/api/backend/action", {
          params: {
            action: "administrators-save",
            login: login,
            id: administratorid,
            name: data.name,
            newlogin: data.login,
            email: data.email,
            active: data.active ? "Y" : "N"
          },
          headers: {
            Authorization: "Bearer " + loginToken
          }
        })
        .then(response => {
          if (response.data.success) {
            setRequeryTime(window.performance.now());
            setSuccessSBMessage("De gegevens werden succesvol opgeslagen");
            setShowSuccessSB(true);
          }
        })
        .catch(error => {
          if (error.response.data.message)
            setErrorSBMessage(error.response.data.message);
          else setErrorSBMessage(error);
          setShowErrorSB(true);
        });
    }
  };

  const handleSavePasswordDialog = () => {
    const form = new FormData();
    form.set("action", "administrators-setpassword");
    form.set("login", login);
    form.set("id", data.id);
    form.set("password", passwordDialogNewPassword);
    axios
      .post(config.api + "/api/backend/action", form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + loginToken
        }
      })
      .then(response => {
        if (response.data.success) {
          setShowPasswordDialog(false);
          setSuccessSBMessage(
            "Het nieuwe wachtwoord werd succesvol opgeslagen"
          );
          setShowSuccessSB(true);
        }
      })
      .catch(error => {
        if (error.response.data.message)
          setErrorSBMessage(error.response.data.message);
        else setErrorSBMessage(error);
        setShowErrorSB(true);
      });
  };
  const handleCancelPasswordDialog = () => {
    setShowPasswordDialog(false);
  };

  const detailCol1 = 3;
  const detailCol2 = 9;
  return (
    <BackendPage title={"Administrator " + data.name}>
      <Grid container spacing={2} component={Paper} alignItems="center">
        <Grid item xs={detailCol1}>
          <Typography className={classes.formLabel}>ID</Typography>
        </Grid>
        <Grid item xs={detailCol2}>
          <Typography>{data.id}</Typography>
        </Grid>
        <Grid item xs={detailCol1}>
          <Typography className={classes.formLabel}>Naam</Typography>
        </Grid>
        <Grid item xs={detailCol2}>
          <TextField
            id="admin-name"
            name="admin-name"
            value={data.name || ""}
            onChange={handleTextfieldChange("name")}
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={detailCol1}>
          <Typography className={classes.formLabel}>Login</Typography>
        </Grid>
        <Grid item xs={detailCol2}>
          <TextField
            id="admin-login"
            name="admin-login"
            value={data.login || ""}
            onChange={handleTextfieldChange("login")}
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={detailCol1}>
          <Typography className={classes.formLabel}>Email</Typography>
        </Grid>
        <Grid item xs={detailCol2}>
          <TextField
            id="admin-email"
            name="admin-email"
            value={data.email || ""}
            onChange={handleTextfieldChange("email")}
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={detailCol1}>
          <Typography className={classes.formLabel}>&nbsp;</Typography>
        </Grid>
        <Grid item xs={detailCol2}>
          <FormControlLabel
            control={
              <Switch
                checked={data.active || false}
                onChange={handleCheckboxChange("active")}
                value="active"
                color="primary"
              />
            }
            label="Actief"
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} className={classes.buttongrid}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionbutton}
            onClick={saveDetails}
          >
            Opslaan
          </Button>
          <Button
            variant="contained"
            className={classes.actionbutton}
            onClick={() => {
              setShowPasswordDialog(true);
            }}
          >
            Nieuw wachtwoord instellen
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={showPasswordDialog}
        onClose={handleCancelPasswordDialog}
        aria-labelledby="wachtwoordform-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="wachtwoordform-dialog-title">
          Wachtwoord instellen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Geef hieronder het nieuwe wachtwoord in (minimum 8 karakters).
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nieuw wachtwoord"
            type="text"
            fullWidth
            value={passwordDialogNewPassword}
            onChange={evt => {
              setPasswordDialogNewPassword(evt.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelPasswordDialog} color="primary">
            Annuleren
          </Button>
          <Button onClick={handleSavePasswordDialog} color="primary">
            Opslaan
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorSnackbar
        open={showErrorSB}
        setOpen={setShowErrorSB}
        message={errorSBMessage}
      />
      <SuccessSnackbar
        open={showSuccessSB}
        setOpen={setShowSuccessSB}
        message={successSBMessage}
      />
    </BackendPage>
  );
}
