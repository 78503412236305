const production = {
  api: "https://backend.circuskean.com/"
};

const development = {
  api: "https://circuskeanbackend.local.zappatic.net/"
};

export const config =
  process.env.NODE_ENV === "development" ? development : production;
