import React, { useContext, useState, useEffect } from "react";
import { config } from "../../Constants";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import LoginContext from "../various/LoginContext.jsx";
import BackendPage from "./BackendPage";
import Countries from "../../countries.json";
import SuccessSnackbar from "../various/SuccessSnackbar";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(theme => ({
  bold: { fontWeight: "bold" },
  green: { color: "green" },
  red: { color: "red" },
  buttongrid: { marginTop: theme.spacing(3) },
  actionbutton: { marginRight: theme.spacing(1) }
}));

export default function Bestelling() {
  const { loggedIn, login, loginToken } = useContext(LoginContext);
  const classes = useStyles();
  let { orderid } = useParams();
  const [order, setOrder] = useState({});
  const [requeryTime, setRequeryTime] = useState(0);
  const [openTrackingNrDialog, setOpenTrackingNrDialog] = useState(false);
  const [dialogTrackingNrValue, setDialogTrackingNrValue] = useState("");
  const [dialogTrackingShippedByValue, setDialogTrackingShippedByValue] = useState("bpost");

  const [openMailTrackingInfoDialog, setOpenMailTrackingInfoDialog] = useState(false);
  const [dialogMailTrackingInfoEmail, setDialogMailTrackingInfoEmail] = useState("");

  const [showSuccessSB, setShowSuccessSB] = useState(false);
  const [successSBMessage, setSuccessSBMessage] = useState("");

  const mappingLogistiek = {
    shiptracked: "Verzenden (+tracking)",
    pickup: "Afhalen",
    ship: "Verzenden"
  };

  const mappingBetaalwijze = {
    creditcard: "PayPal/Kredietkaart",
    cash: "Cash",
    banktransfer: "Bankoverschrijving"
  };

  const mappingTaal = {
    nl: "Nederlands",
    en: "English",
    fr: "Français"
  };

  useEffect(() => {
    if (loggedIn) {
      axios
        .get(config.api + "/api/backend/action", {
          params: {
            action: "orders-detail",
            login: login,
            id: orderid
          },
          headers: {
            Authorization: "Bearer " + loginToken
          }
        })
        .then(response => {
          if (response.data.success) {
            response.data.order.countryname = Countries.filter(country => country.Code === response.data.order.country)[0].Name;

            response.data.order.trackingurl = "";
            if (response.data.order.trackingnumber !== "") {
              if (response.data.order.shipped_by === "bpost") {
                response.data.order.trackingurl = "http://track.bpost.be/btr/web/#/search?lang=nl&itemCode=" + order.trackingnumber + "&postalCode=" + order.postalcode;
              } else if (response.data.order.shipped_by === "postnl") {
                response.data.order.trackingurl = "https://jouw.postnl.nl/#!/track-en-trace/" + order.trackingnumber + "/" + order.country + "/" + order.postalcode;
              }
            }

            setDialogMailTrackingInfoEmail(response.data.order.email);
            setOrder(response.data.order);
          }
        });
    }
  }, [loggedIn, login, loginToken, orderid, requeryTime, order.postalcode, order.trackingnumber]);

  const togglePaidStatus = () => {
    const form = new FormData();
    form.set("action", "orders-togglepaid");
    form.set("login", login);
    form.set("id", orderid);
    axios
      .post(config.api + "/api/backend/action", form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + loginToken
        }
      })
      .then(response => {
        if (response.data.success) {
          setSuccessSBMessage("De gegevens werden succesvol opgeslagen");
          setShowSuccessSB(true);
          setRequeryTime(window.performance.now());
        }
      });
  };

  const toggleShippedStatus = () => {
    const form = new FormData();
    form.set("action", "orders-toggleshipped");
    form.set("login", login);
    form.set("id", orderid);
    axios
      .post(config.api + "/api/backend/action", form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + loginToken
        }
      })
      .then(response => {
        if (response.data.success) {
          setSuccessSBMessage("De gegevens werden succesvol opgeslagen");
          setShowSuccessSB(true);
          setRequeryTime(window.performance.now());
        }
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const togglePickedUpStatus = () => {
    const form = new FormData();
    form.set("action", "orders-togglepickedup");
    form.set("login", login);
    form.set("id", orderid);
    axios
      .post(config.api + "/api/backend/action", form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + loginToken
        }
      })
      .then(response => {
        if (response.data.success) {
          setSuccessSBMessage("De gegevens werden succesvol opgeslagen");
          setShowSuccessSB(true);
          setRequeryTime(window.performance.now());
        }
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const handleSaveTrackingNrDialog = () => {
    const form = new FormData();
    form.set("action", "orders-settrackingnr");
    form.set("login", login);
    form.set("id", orderid);
    form.set("trackingnr", dialogTrackingNrValue);
    form.set("shipped_by", dialogTrackingShippedByValue);
    axios
      .post(config.api + "/api/backend/action", form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + loginToken
        }
      })
      .then(response => {
        if (response.data.success) {
          setSuccessSBMessage("De gegevens werden succesvol opgeslagen");
          setShowSuccessSB(true);
          setOpenTrackingNrDialog(false);
          setRequeryTime(window.performance.now());
        }
      })
      .catch(error => {
        console.log(error.response);
      });
  };
  const handleCancelTrackingNrDialog = () => {
    setOpenTrackingNrDialog(false);
  };

  const handleSendMailTrackingInfoDialog = () => {
    const form = new FormData();
    form.set("action", "orders-mailtrackinginfo");
    form.set("login", login);
    form.set("id", orderid);
    form.set("recipient", dialogMailTrackingInfoEmail);
    axios
      .post(config.api + "/api/backend/action", form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + loginToken
        }
      })
      .then(response => {
        if (response.data.success) {
          setSuccessSBMessage("De mail werd verzonden");
          setShowSuccessSB(true);
          setOpenMailTrackingInfoDialog(false);
        }
      })
      .catch(error => {
        console.log(error.response);
      });
  };
  const handleCancelMailTrackingInfoDialog = () => {
    setOpenMailTrackingInfoDialog(false);
  };

  const detailCol1 = 4;
  const detailCol2 = 8;
  return (
    <BackendPage title={"Bestelling " + order.orderid}>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <Grid container spacing={3} component={Paper}>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Bestelnummer</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.orderid}</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Datum</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.date_created}</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Taal van de klant</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{mappingTaal[order.language]}</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Naam</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.name}</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Adres</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.streetnr} </Typography>
              <Typography>
                {order.postalcode} {order.city}
              </Typography>
              {order.state ? <Typography>{order.state}</Typography> : null}
              <Typography>{order.countryname}</Typography>
              {order.latitude && order.longitude ? (
                <React.Fragment>
                  <br />
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ marginTop: 5 }}
                    href={"https://www.google.com/maps/search/" + order.latitude + "," + order.longitude}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Toon op kaart
                  </Button>
                </React.Fragment>
              ) : null}
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Email</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>
                <a href={"mailto:" + order.email}>{order.email}</a>
              </Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Telefoon</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.phonenumber}</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Aantal boeken (EN)</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.amounten}</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Aantal boeken (NL)</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.amountnl}</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Aantal boeken (FR)</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.amountfr}</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Betaalwijze</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{mappingBetaalwijze[order.paymentmethod]}</Typography>
            </Grid>
            {order.paymentmethod === "creditcard" && order.paypaltransaction ? (
              <React.Fragment>
                <Grid item xs={detailCol1}>
                  <Typography className={classes.bold}>PayPal transactie</Typography>
                </Grid>
                <Grid item xs={detailCol2}>
                  <Typography>{order.paypaltransaction}</Typography>
                </Grid>
              </React.Fragment>
            ) : null}
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Logistiek</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{mappingLogistiek[order.shiporpickup]}</Typography>
            </Grid>
            {order.shipped ? (
              <React.Fragment>
                <Grid item xs={detailCol1}>
                  <Typography className={classes.bold}>Verzonden op</Typography>
                </Grid>
                <Grid item xs={detailCol2}>
                  <Typography>{order.shipped_date}</Typography>
                </Grid>
              </React.Fragment>
            ) : null}
            {order.trackingnumber ? (
              <React.Fragment>
                <Grid item xs={detailCol1}>
                  <Typography className={classes.bold}>Tracking nr.</Typography>
                </Grid>
                <Grid item xs={detailCol2}>
                  <Typography>
                    <a target="_blank" rel="noopener noreferrer" href={order.trackingurl}>
                      {order.trackingnumber}
                    </a>{" "}
                    ({order.shipped_by})
                  </Typography>
                </Grid>
              </React.Fragment>
            ) : null}
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Gewicht</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.weight} kg</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Verzendkosten</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.shippingcost} &euro;</Typography>
            </Grid>
            <Grid item xs={detailCol1}>
              <Typography className={classes.bold}>Totaal kost</Typography>
            </Grid>
            <Grid item xs={detailCol2}>
              <Typography>{order.totalprice} &euro;</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={3} component={Paper}>
            <Grid item xs={2} align="right">
              {order.paid ? <CheckIcon className={classes.green} /> : <CloseIcon className={classes.red} />}
            </Grid>
            <Grid item xs={10}>
              <Typography className={classes.bold}>Betaald</Typography>
            </Grid>
            {order.shiporpickup === "ship" || order.shiporpickup === "shiptracked" ? (
              <React.Fragment>
                <Grid item xs={2} align="right">
                  {order.shipped ? <CheckIcon className={classes.green} /> : <CloseIcon className={classes.red} />}
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.bold}>Verzonden</Typography>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={2} align="right">
                  {order.pickedup ? <CheckIcon className={classes.green} /> : <CloseIcon className={classes.red} />}
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.bold}>Afgehaald</Typography>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={5} className={classes.buttongrid}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionbutton}
            onClick={evt => {
              togglePaidStatus();
            }}
          >
            Markeer als betaald
          </Button>
          {order.shiporpickup === "ship" || order.shiporpickup === "shiptracked" ? (
            <React.Fragment>
              <Button
                variant="contained"
                color="primary"
                className={classes.actionbutton}
                onClick={evt => {
                  toggleShippedStatus();
                }}
              >
                Markeer als verzonden
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.actionbutton}
                onClick={evt => {
                  setOpenTrackingNrDialog(true);
                }}
              >
                Tracking nr. invoegen
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                variant="contained"
                color="primary"
                className={classes.actionbutton}
                onClick={evt => {
                  togglePickedUpStatus();
                }}
              >
                Markeer als afgehaald
              </Button>
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" className={classes.actionbutton} href={"https://circuskean.com/order/" + order.hash + "/" + order.id} target="_blank">
            Toon op frontend
          </Button>
          {order.trackingnumber !== "" ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.actionbutton}
              onClick={evt => {
                setOpenMailTrackingInfoDialog(true);
              }}
            >
              Mail tracking info
            </Button>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Grid>
      </Grid>
      <Dialog open={openTrackingNrDialog} onClose={handleCancelTrackingNrDialog} aria-labelledby="trackingform-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="trackingform-dialog-title">Tracking nummer</DialogTitle>
        <DialogContent>
          <DialogContentText>Geef hieronder het tracking nummer in.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tracking nr."
            type="text"
            fullWidth
            value={dialogTrackingNrValue}
            onChange={evt => {
              setDialogTrackingNrValue(evt.target.value);
            }}
          />
          <RadioGroup
            aria-label="Shipped by"
            name="shipped_by"
            value={dialogTrackingShippedByValue}
            onChange={evt => {
              setDialogTrackingShippedByValue(evt.target.value);
            }}
          >
            <FormControlLabel value="bpost" control={<Radio />} label="bpost" />
            <FormControlLabel value="postnl" control={<Radio />} label="PostNL" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelTrackingNrDialog} color="primary">
            Annuleren
          </Button>
          <Button onClick={handleSaveTrackingNrDialog} color="primary">
            Opslaan
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMailTrackingInfoDialog} onClose={handleCancelMailTrackingInfoDialog} aria-labelledby="mailtrackingform-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="mailtrackingform-dialog-title">Mail tracking info</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="text"
            fullWidth
            value={dialogMailTrackingInfoEmail}
            onChange={evt => {
              setDialogMailTrackingInfoEmail(evt.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelMailTrackingInfoDialog} color="primary">
            Annuleren
          </Button>
          <Button onClick={handleSendMailTrackingInfoDialog} color="primary">
            Versturen
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessSnackbar open={showSuccessSB} setOpen={setShowSuccessSB} message={successSBMessage} />
    </BackendPage>
  );
}
