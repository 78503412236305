import React, { useContext, useEffect, useState } from "react";
import { config } from "../../Constants";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import LoginContext from "../various/LoginContext.jsx";
import BackendPage from "./BackendPage";

import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  table: {
    minWidth: 650
  },
  green: { color: "green" },
  pointer: { cursor: "pointer" }
}));

const useStylesTPA = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStylesTPA();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="eerste pagina"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="vorige pagina"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="volgende pagina"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="laatste pagina"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default function Dashboard() {
  const { loggedIn, login, loginToken } = useContext(LoginContext);
  const classes = useStyles();
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const mappingLogistiek = {
    shiptracked: "Verzenden (+tracking)",
    pickup: "Afhalen",
    ship: "Verzenden"
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (loggedIn) {
      if (totalRowCount === 0) {
        axios
          .get(config.api + "/api/backend/action", {
            params: {
              action: "orders-getmeta",
              login: login
            },
            headers: {
              Authorization: "Bearer " + loginToken
            }
          })
          .then(response => {
            if (response.data.success) {
              setTotalRowCount(response.data.totalrowcount);
            }
          });
      }
    }
  }, [loggedIn, login, loginToken, totalRowCount]);

  useEffect(() => {
    axios
      .get(config.api + "/api/backend/action", {
        params: {
          action: "orders-get",
          login: login,
          page: page,
          perpage: rowsPerPage
        },
        headers: {
          Authorization: "Bearer " + loginToken
        }
      })
      .then(response => {
        if (response.data.success) {
          setRows(response.data.orders);
        }
      });
  }, [loggedIn, login, loginToken, page, rowsPerPage]);

  return (
    <BackendPage title="Bestellingen">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="Bestellingen"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Naam</TableCell>
                  <TableCell>Datum</TableCell>
                  <TableCell>Prijs</TableCell>
                  <TableCell align="right">Logistiek</TableCell>
                  <TableCell align="right">Betaald</TableCell>
                  <TableCell align="right">Afgehandeld</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, mapix) => (
                  <TableRow
                    style={
                      mapix % 2
                        ? { background: "#eee" }
                        : { background: "white" }
                    }
                    key={row.id}
                    className={classes.pointer}
                    onClick={event => {
                      history.push("/bestelling/" + row.id);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.orderid}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {row.date_created}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {row.totalprice} &euro;
                    </TableCell>
                    <TableCell align="right">
                      {mappingLogistiek[row.shiporpickup]}
                    </TableCell>
                    <TableCell align="center">
                      {row.paid ? (
                        <CheckIcon className={classes.green} />
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      {row.shipped || row.pickedup ? (
                        <CheckIcon className={classes.green} />
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={7}
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Per pagina"
                    SelectProps={{
                      inputProps: { "aria-label": "per pagina" },
                      native: true
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </BackendPage>
  );
}
