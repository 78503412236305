import React, { useContext, useState, useEffect } from "react";
import { config } from "../../Constants";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import LoginContext from "../various/LoginContext.jsx";
import BackendPage from "./BackendPage";
import ErrorSnackbar from "../various/ErrorSnackbar";

import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  table: {
    minWidth: 650
  },
  green: { color: "green" },
  pointer: { cursor: "pointer" }
}));

const useStylesTPA = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStylesTPA();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="eerste pagina"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="vorige pagina"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="volgende pagina"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="laatste pagina"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default function Administrators() {
  const { loggedIn, login, loginToken } = useContext(LoginContext);
  const classes = useStyles();
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showNewAdministratorDialog, setShowNewAdministratorDialog] = useState(
    false
  );
  const [newAdministratorLogin, setNewAdministratorLogin] = useState("");
  const [showErrorSB, setShowErrorSB] = useState(false);
  const [errorSBMessage, setErrorSBMessage] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (loggedIn) {
      if (totalRowCount === 0) {
        axios
          .get(config.api + "/api/backend/action", {
            params: {
              action: "administrators-getmeta",
              login: login
            },
            headers: {
              Authorization: "Bearer " + loginToken
            }
          })
          .then(response => {
            if (response.data.success) {
              setTotalRowCount(response.data.totalrowcount);
            }
          });
      }
    }
  }, [loggedIn, login, loginToken, totalRowCount]);

  useEffect(() => {
    axios
      .get(config.api + "/api/backend/action", {
        params: {
          action: "administrators-get",
          login: login,
          page: page,
          perpage: rowsPerPage
        },
        headers: {
          Authorization: "Bearer " + loginToken
        }
      })
      .then(response => {
        if (response.data.success) {
          setRows(response.data.data);
        }
      });
  }, [loggedIn, login, loginToken, page, rowsPerPage]);

  const handleCreateNewAdministratorDialog = () => {
    const form = new FormData();
    form.set("action", "administrators-create");
    form.set("login", login);
    form.set("newlogin", newAdministratorLogin);
    axios
      .post(config.api + "/api/backend/action", form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + loginToken
        }
      })
      .then(response => {
        if (response.data.success) {
          history.push("/administrator/" + response.data.id);
        }
      })
      .catch(error => {
        if (error.response.data.message)
          setErrorSBMessage(error.response.data.message);
        else setErrorSBMessage(error);
        setShowErrorSB(true);
      });
  };
  const handleCancelNewAdministratorDialog = () => {
    setShowNewAdministratorDialog(false);
    setNewAdministratorLogin("");
  };

  return (
    <BackendPage title="Administrators">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="Administrators"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Login</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="center">Actief</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, mapix) => (
                  <TableRow
                    style={
                      mapix % 2
                        ? { background: "#eee" }
                        : { background: "white" }
                    }
                    key={row.id}
                    className={classes.pointer}
                    onClick={event => {
                      history.push("/administrator/" + row.id);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.login}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="center">
                      {row.active ? (
                        <CheckIcon className={classes.green} />
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={5}
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Per pagina"
                    SelectProps={{
                      inputProps: { "aria-label": "per pagina" },
                      native: true
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionbutton}
            onClick={() => {
              setShowNewAdministratorDialog(true);
            }}
          >
            Nieuwe beheerder
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={showNewAdministratorDialog}
        onClose={handleCancelNewAdministratorDialog}
        aria-labelledby="wachtwoordform-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="wachtwoordform-dialog-title">
          Nieuwe beheerder
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Geef hieronder de unieke login van de nieuwe gebruiker in.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Login"
            type="text"
            fullWidth
            value={newAdministratorLogin}
            onChange={evt => {
              setNewAdministratorLogin(evt.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelNewAdministratorDialog} color="primary">
            Annuleren
          </Button>
          <Button onClick={handleCreateNewAdministratorDialog} color="primary">
            Aanmaken
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorSnackbar
        open={showErrorSB}
        setOpen={setShowErrorSB}
        message={errorSBMessage}
      />
    </BackendPage>
  );
}
