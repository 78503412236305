import React, { useContext, useEffect, useState } from "react";
import { config } from "../../Constants";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import axios from "axios";
import LoginContext from "../various/LoginContext.jsx";
import BackendPage from "./BackendPage";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";

import LanguageDistributionChart from "../various/DashboardLangDistChart.jsx";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  table: {
    minWidth: 650,
    tableLayout: "auto"
  },
  green: { color: "green" },
  pointer: { cursor: "pointer" },
  fixedHeight: {
    height: 200
  }
}));

export default function Dashboard() {
  const { loggedIn, login, loginToken } = useContext(LoginContext);
  const classes = useStyles();
  const history = useHistory();
  const [map, setMap] = useState({});
  const [mapInitialized, setMapInitialized] = useState(false);

  const [dashboardData, setDashboardData] = useState({
    last10orders: [],
    sales: { languageDistribution: [] },
    mapmarkers: []
  });

  const mappingLogistiek = {
    shiptracked: "Verzenden (+tracking)",
    pickup: "Afhalen",
    ship: "Verzenden"
  };

  useEffect(() => {
    if (loggedIn) {
      axios
        .get(config.api + "/api/backend/action", {
          params: {
            action: "dashboard-get",
            login: login
          },
          headers: {
            Authorization: "Bearer " + loginToken
          }
        })
        .then(response => {
          if (response.data.success) {
            setDashboardData(response.data.data);
          }
        });
    }
  }, [loggedIn, login, loginToken]);

  useEffect(() => {
    if (!mapInitialized) {
      window.L.mapquest.key = "b2gxDWfHbdmXs1i10DIMmsKi85qUt8hA";

      var m = window.L.mapquest.map("ordermap", {
        center: [51.075912, 4.711227],
        layers: window.L.mapquest.tileLayer("map"),
        zoom: 2
      });
      m.addControl(window.L.mapquest.control());
      setMap(m);
      setMapInitialized(true);
    }

    if (mapInitialized) {
      for (var ma of dashboardData.mapmarkers) {
        window.L.marker([ma.latitude, ma.longitude], {
          icon: window.L.mapquest.icons.marker(),
          draggable: false
        })
          .bindPopup(ma.name)
          .addTo(map);
      }
    }
  }, [dashboardData, mapInitialized, map]);

  return (
    <BackendPage title="Dashboard">
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Verkoop
            </Typography>
            <Typography>
              Aantal bestellingen: {dashboardData.sales.amountOfOrders}
            </Typography>
            <Typography>
              Aantal boeken: {dashboardData.sales.amountOfBooks}
            </Typography>
            <Typography>
              Aantal betaald: {dashboardData.sales.amountOfPaidOrders} /{" "}
              {dashboardData.sales.amountOfOrders}
            </Typography>
            <Typography>
              Aantal verzonden: {dashboardData.sales.amountOfShippedOrders} /{" "}
              {dashboardData.sales.amountOfOrdersToBeShipped}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Inkomsten
            </Typography>
            <Typography>
              Bestellingen: {dashboardData.sales.incomeAll} &euro;
            </Typography>
            <Typography>
              Effectief betaald: <b>{dashboardData.sales.incomePaid} &euro;</b>
            </Typography>
            <Typography>
              Eff. verzendkosten:{" "}
              <b>-{dashboardData.sales.effectiveShippingcost} &euro;</b>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Taalverdeling
            </Typography>
            <div style={{ height: 150 }}>
              <LanguageDistributionChart
                data={dashboardData.sales.languageDistribution}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Laatste 10 bestellingen
            </Typography>
            <Table
              className={classes.table}
              size="small"
              aria-label="Laatste 10 bestellingen"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Naam</TableCell>
                  <TableCell>Datum</TableCell>
                  <TableCell>Prijs</TableCell>
                  <TableCell align="right">Logistiek</TableCell>
                  <TableCell align="right">Betaald</TableCell>
                  <TableCell align="right">Afgehandeld</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardData.last10orders.map((row, mapix) => (
                  <TableRow
                    style={
                      mapix % 2
                        ? { background: "#eee" }
                        : { background: "white" }
                    }
                    key={row.id}
                    className={classes.pointer}
                    onClick={event => {
                      history.push("/bestelling/" + row.id);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.orderid}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {row.date_created}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {row.totalprice} &euro;
                    </TableCell>
                    <TableCell align="right">
                      {mappingLogistiek[row.shiporpickup]}
                    </TableCell>
                    <TableCell align="center">
                      {row.paid ? (
                        <CheckIcon className={classes.green} />
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      {row.shipped || row.pickedup ? (
                        <CheckIcon className={classes.green} />
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Voltooide bestellingen
            </Typography>
            <div id="ordermap" style={{ width: "100%", height: 530 }}></div>
          </Paper>
        </Grid>
      </Grid>
    </BackendPage>
  );
}
