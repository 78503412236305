import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { green } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  success: {
    backgroundColor: green[600]
  }
}));

export default function SuccessSnackbar(props) {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      autoHideDuration={1600}
    >
      <SnackbarContent
        className={classes.success}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-describedby="successsb"
        message={
          <span id="successsb" className={classes.message}>
            <CheckCircleOutlineIcon
              className={clsx(classes.icon, classes.iconVariant)}
            />
            {props.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}
