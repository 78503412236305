import React, { useState, useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "./components/various/history.js";
import "./App.css";
import { config } from "./Constants";
import axios from "axios";
import CssBaseline from "@material-ui/core/CssBaseline";
import ScrollToTop from "./components/various/ScrollToTop.jsx";
import LoginContext from "./components/various/LoginContext.jsx";
import Login from "./components/pages/Login.jsx";
import Dashboard from "./components/pages/Dashboard.jsx";
import Bestellingen from "./components/pages/Bestellingen.jsx";
import Bestelling from "./components/pages/Bestelling.jsx";
import Administrators from "./components/pages/Administrators.jsx";
import Administrator from "./components/pages/Administrator.jsx";

export default function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginToken, setLoginToken] = useState("");
  const [login, setLogin] = useState("");

  // check if a saved token exists and if it is still valid
  useEffect(() => {
    if (!loggedIn) {
      const savedLoginToken = localStorage.getItem("ck-login-token");
      const savedLogin = localStorage.getItem("ck-login");

      if (savedLoginToken !== "" && savedLogin !== "") {
        axios
          .get(config.api + "/api/backend/action", {
            params: {
              action: "login-checktoken",
              login: savedLogin
            },
            headers: {
              Authorization: "Bearer " + savedLoginToken
            }
          })
          .then(response => {
            if (response.data.success) {
              performLogin(savedLogin, savedLoginToken);
              history.push("/dashboard");
            }
          });
      }
    }
  }, [loggedIn]);

  const performLogin = (login, token) => {
    setLoggedIn(true);
    setLoginToken(token);
    setLogin(login);

    localStorage.setItem("ck-login-token", token);
    localStorage.setItem("ck-login", login);
  };

  const performLogoff = () => {
    setLoggedIn(false);
    setLoginToken("");
    setLogin("");
    localStorage.removeItem("ck-login-token");
    localStorage.removeItem("ck-login");
    history.push("/");
  };

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        loggedIn === true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <Router history={history}>
        <ScrollToTop />
        <LoginContext.Provider
          value={{ loggedIn, login, loginToken, performLogin, performLogoff }}
        >
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/login" component={Login} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute
              path="/bestelling/:orderid([0-9]+)"
              component={Bestelling}
            />
            <PrivateRoute path="/bestellingen" component={Bestellingen} />
            <PrivateRoute
              path="/administrator/:administratorid([0-9]+)"
              component={Administrator}
            />
            <PrivateRoute path="/administrators" component={Administrators} />
          </Switch>
        </LoginContext.Provider>
      </Router>
    </React.Fragment>
  );
}
