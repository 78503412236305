import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { config } from "../../Constants";
import LoginContext from "../various/LoginContext.jsx";
import axios from "axios";
import ErrorSnackbar from "../various/ErrorSnackbar";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const { performLogin, performLogoff } = useContext(LoginContext);

  const [formLogin, setFormLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showErrorSB, setShowErrorSB] = useState(false);
  const [errorSBMessage, setErrorSBMessage] = useState("");

  const submitLoginForm = e => {
    e.preventDefault();

    axios
      .get(config.api + "/api/backend/action", {
        params: {
          action: "login-login",
          login: formLogin,
          password: password
        }
      })
      .then(response => {
        if (response.data.success) {
          performLogin(formLogin, response.data.token);
          history.push("/dashboard");
        } else {
          performLogoff();
          setErrorSBMessage(response.data.message);
          setShowErrorSB(true);
        }
      })
      .catch(error => {
        performLogoff();
        console.log(error);
        console.log(error.response);
        if (error.response) {
          if (error.response.data.message) {
            setErrorSBMessage(error.response.data.message);
          } else {
            setErrorSBMessage(
              error.response.status + " " + error.response.statusText
            );
          }
        }
        setShowErrorSB(true);
      });
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Aanmelden
          </Typography>
          <form className={classes.form} noValidate onSubmit={submitLoginForm}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="login"
              label="Login"
              name="login"
              value={formLogin}
              onChange={evt => {
                setFormLogin(evt.target.value);
              }}
              autoComplete="login"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Wachtwoord"
              type="password"
              id="password"
              value={password}
              onChange={evt => {
                setPassword(evt.target.value);
              }}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Aanmelden
            </Button>
          </form>
        </div>
      </Container>
      <ErrorSnackbar
        open={showErrorSB}
        setOpen={setShowErrorSB}
        message={errorSBMessage}
      />
    </React.Fragment>
  );
}
